.ag-fresh .ag-root {
  border: 1px solid #808080;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ag-fresh .ag-cell {
  border-right: 1px solid #808080;
  padding: 4px;
}
.ag-fresh .ag-pinned-header {
  /*background: -webkit-linear-gradient(#fff, #d3d3d3);
  background: -moz-linear-gradient(#fff, #d3d3d3);
  background: -o-linear-gradient(#fff, #d3d3d3);
  background: -ms-linear-gradient(#fff, #d3d3d3);
  background: linear-gradient(#fff, #d3d3d3);*/
  border-bottom: 1px solid #808080;
}
.ag-fresh .ag-header-container {
  /*background: -webkit-linear-gradient(#fff, #d3d3d3);
  background: -moz-linear-gradient(#fff, #d3d3d3);
  background: -o-linear-gradient(#fff, #d3d3d3);
  background: -ms-linear-gradient(#fff, #d3d3d3);
  background: linear-gradient(#fff, #d3d3d3);*/
  background-color: #f5f5f5;
  font-weight: 800;
  border-bottom: 1px solid #808080;
}
.ag-fresh .ag-header-cell {
  border-right: 1px solid #808080;
}
.ag-fresh .ag-header-group-cell {
  border-right: 1px solid #808080;
}
.ag-fresh .ag-header-group-cell-with-group {
  border-bottom: 1px solid #808080;
}
.ag-fresh .ag-header-cell-label {
  padding: 4px 2px 4px 2px;
}
.ag-fresh .ag-header-cell-text {
  padding-left: 2px;
}
.ag-fresh .ag-header-group-cell-label {
  padding: 4px;
  font-weight: bold;
}
.ag-fresh .ag-header-group-text {
  margin-right: 2px;
}
.ag-fresh .ag-header-cell-menu-button {
  padding: 2px;
  margin-top: 4px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; /* When using bootstrap, box-sizing was set to 'border-box' */
}
.ag-fresh .ag-header-cell-menu-button:hover {
  border: 1px solid #000;
}
.ag-fresh .ag-header-icon {
  color: #800000;
}
.ag-fresh .ag-dark .ag-header-expand-icon:hover {
  cursor: pointer;
}
.ag-fresh .ag-row-odd {
  background-color: #f6f6f6;
}
.ag-fresh .ag-row-even {
  background-color: #fff;
}
.ag-fresh .ag-loading-panel {
  background-color: rgba(255,255,255,0.5);
}
.ag-fresh .ag-loading-center {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
}
.ag-fresh .ag-body {
  /*background-color: #ddd;*/
}
.ag-fresh .ag-row-selected {
  background-color: #b2dfee;
}
.ag-fresh .ag-group-cell-entire-row {
  background-color: #aaa;
  padding: 4px;
}
.ag-fresh .ag-footer-cell-entire-row {
  background-color: #aaa;
  padding: 4px;
}
.ag-fresh .ag-group-cell {
  font-style: italic;
}
.ag-fresh .ag-footer-cell {
  font-style: italic;
}
.ag-fresh .ag-filter-checkbox {
  position: relative;
  top: 2px;
  left: 2px;
}
.ag-fresh .ag-filter-header-container {
  border-bottom: 1px solid #d3d3d3;
}
.ag-fresh .ag-filter {
  border: 1px solid #000;
  background-color: #f0f0f0;
}
.ag-fresh .ag-selection-checkbox {
  margin-left: 4px;
}
.ag-fresh .ag-paging-panel {
  padding: 4px;
}
.ag-fresh .ag-paging-button {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-fresh .ag-paging-row-summary-panel {
  display: inline-block;
  width: 300px;
}
