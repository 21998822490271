/** HELP sidebar */
div#show-help-sidebar {
  position: fixed;
  top: 55px;
  right: 18px;
  z-index: 2;
}
tr.attr-not-editable > td {
  color: #999;
}
/* small inline forms, such as team admin new field form */
.form-group.sm-il-form {
  margin-right: 10px;
}
span.sblogo {
  background-color: #dd4814;
  padding: 7px;
  border-radius: 4px;
  color: white;
}
span.sblogo i {
  color: #FDC9C9;
  margin: 0 1px;
}
/** colour picker **/
.tag-col.bg-color-ad61ed {
  background-color: #AD61ED;
  color: #FFF;
}
.tag-col.bg-color-ad61ed::after {
  border-left-color: #AD61ED;
}
.tag-col.bg-color-ad61ed:hover {
  background-color: #b978f0;
}
.tag-col.bg-color-ad61ed:hover::after {
  border-left-color: #b978f0;
}
.tag-col.fg-color-ad61ed {
  color: #AD61ED;
}
.tag-col.bg-color-45b4e7 {
  background-color: #45B4E7;
  color: #333;
}
.tag-col.bg-color-45b4e7::after {
  border-left-color: #45B4E7;
}
.tag-col.bg-color-45b4e7:hover {
  background-color: #5cbdea;
}
.tag-col.bg-color-45b4e7:hover::after {
  border-left-color: #5cbdea;
}
.tag-col.fg-color-45b4e7 {
  color: #45B4E7;
}
.tag-col.bg-color-b2f068 {
  background-color: #B2F068;
  color: #333;
}
.tag-col.bg-color-b2f068::after {
  border-left-color: #B2F068;
}
.tag-col.bg-color-b2f068:hover {
  background-color: #bef27f;
}
.tag-col.bg-color-b2f068:hover::after {
  border-left-color: #bef27f;
}
.tag-col.fg-color-b2f068 {
  color: #B2F068;
}
.tag-col.bg-color-ffba6d {
  background-color: #FFBA6D;
  color: #333;
}
.tag-col.bg-color-ffba6d::after {
  border-left-color: #FFBA6D;
}
.tag-col.bg-color-ffba6d:hover {
  background-color: #ffc687;
}
.tag-col.bg-color-ffba6d:hover::after {
  border-left-color: #ffc687;
}
.tag-col.fg-color-ffba6d {
  color: #FFBA6D;
}
.tag-col.bg-color-fda6f8 {
  background-color: #FDA6F8;
  color: #333;
}
.tag-col.bg-color-fda6f8::after {
  border-left-color: #FDA6F8;
}
.tag-col.bg-color-fda6f8:hover {
  background-color: #febffa;
}
.tag-col.bg-color-fda6f8:hover::after {
  border-left-color: #febffa;
}
.tag-col.fg-color-fda6f8 {
  color: #FDA6F8;
}
.tag-col.bg-color-7e69ff {
  background-color: #7E69FF;
  color: #FFF;
}
.tag-col.bg-color-7e69ff::after {
  border-left-color: #7E69FF;
}
.tag-col.bg-color-7e69ff:hover {
  background-color: #9483ff;
}
.tag-col.bg-color-7e69ff:hover::after {
  border-left-color: #9483ff;
}
.tag-col.fg-color-7e69ff {
  color: #7E69FF;
}
.tag-col.bg-color-6aecf4 {
  background-color: #6AECF4;
  color: #333;
}
.tag-col.bg-color-6aecf4::after {
  border-left-color: #6AECF4;
}
.tag-col.bg-color-6aecf4:hover {
  background-color: #82eff6;
}
.tag-col.bg-color-6aecf4:hover::after {
  border-left-color: #82eff6;
}
.tag-col.fg-color-6aecf4 {
  color: #6AECF4;
}
.tag-col.bg-color-daff75 {
  background-color: #DAFF75;
  color: #333;
}
.tag-col.bg-color-daff75::after {
  border-left-color: #DAFF75;
}
.tag-col.bg-color-daff75:hover {
  background-color: #e1ff8f;
}
.tag-col.bg-color-daff75:hover::after {
  border-left-color: #e1ff8f;
}
.tag-col.fg-color-daff75 {
  color: #DAFF75;
}
.tag-col.bg-color-fe816d {
  background-color: #FE816D;
  color: #333;
}
.tag-col.bg-color-fe816d::after {
  border-left-color: #FE816D;
}
.tag-col.bg-color-fe816d:hover {
  background-color: #fe9786;
}
.tag-col.bg-color-fe816d:hover::after {
  border-left-color: #fe9786;
}
.tag-col.fg-color-fe816d {
  color: #FE816D;
}
.tag-col.bg-color-d187ef {
  background-color: #D187EF;
  color: #333;
}
.tag-col.bg-color-d187ef::after {
  border-left-color: #D187EF;
}
.tag-col.bg-color-d187ef:hover {
  background-color: #da9ef2;
}
.tag-col.bg-color-d187ef:hover::after {
  border-left-color: #da9ef2;
}
.tag-col.fg-color-d187ef {
  color: #D187EF;
}
.tag-col.bg-color-4573e7 {
  background-color: #4573E7;
  color: #FFF;
}
.tag-col.bg-color-4573e7::after {
  border-left-color: #4573E7;
}
.tag-col.bg-color-4573e7:hover {
  background-color: #5c84ea;
}
.tag-col.bg-color-4573e7:hover::after {
  border-left-color: #5c84ea;
}
.tag-col.fg-color-4573e7 {
  color: #4573E7;
}
.tag-col.bg-color-9ff3c3 {
  background-color: #9FF3C3;
  color: #333;
}
.tag-col.bg-color-9ff3c3::after {
  border-left-color: #9FF3C3;
}
.tag-col.bg-color-9ff3c3:hover {
  background-color: #b6f6d1;
}
.tag-col.bg-color-9ff3c3:hover::after {
  border-left-color: #b6f6d1;
}
.tag-col.fg-color-9ff3c3 {
  color: #9FF3C3;
}
.tag-col.bg-color-ffdd75 {
  background-color: #FFDD75;
  color: #333;
}
.tag-col.bg-color-ffdd75::after {
  border-left-color: #FFDD75;
}
.tag-col.bg-color-ffdd75:hover {
  background-color: #ffe38f;
}
.tag-col.bg-color-ffdd75:hover::after {
  border-left-color: #ffe38f;
}
.tag-col.fg-color-ffdd75 {
  color: #FFDD75;
}
.tag-col.bg-color-ec6f86 {
  background-color: #EC6F86;
  color: #333;
}
.tag-col.bg-color-ec6f86::after {
  border-left-color: #EC6F86;
}
.tag-col.bg-color-ec6f86:hover {
  background-color: #ef8699;
}
.tag-col.bg-color-ec6f86:hover::after {
  border-left-color: #ef8699;
}
.tag-col.fg-color-ec6f86 {
  color: #EC6F86;
}
.tag-col.bg-color-ada7fc {
  background-color: #ADA7FC;
  color: #333;
}
.tag-col.bg-color-ada7fc::after {
  border-left-color: #ADA7FC;
}
.tag-col.bg-color-ada7fc:hover {
  background-color: #c4c0fd;
}
.tag-col.bg-color-ada7fc:hover::after {
  border-left-color: #c4c0fd;
}
.tag-col.fg-color-ada7fc {
  color: #ADA7FC;
}
.tag-col.bg-color-666666 {
  background-color: #666666;
  color: #FFF;
}
.tag-col.bg-color-666666::after {
  border-left-color: #666666;
}
.tag-col.bg-color-666666:hover {
  background-color: #737373;
}
.tag-col.bg-color-666666:hover::after {
  border-left-color: #737373;
}
.tag-col.fg-color-666666 {
  color: #666666;
}
.tag {
  font-size: 14px;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 14px 0 15px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  /*
  &::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }
  */
  /*
  &::after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left-width: 10px;
    border-left-style: solid;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    background-color: crimson;
    color: white;
  }

  &:hover::after {
    border-left-color: crimson; 
  }
  */
}
.modal-content {
  padding: 15px;
}
div#member-grid {
  width: 100%;
  min-height: 600px;
}
tr.group-header {
  background-color: #f9f9f6;
}
tr.group-data td {
  border: 1px solid #e4e4e4;
}
tr.group-data td.title {
  width: 25%;
  text-align: right;
  font-weight: bold;
}
.mb30 {
  margin-bottom: 30px;
}
.mt30 {
  margin-top: 30px;
}
/* grid search conf */
span.grid-search-option {
  display: inline-block;
  margin-right: 17px;
}
span.grid-search-option label {
  margin-bottom: 0px;
}
div.tag-holder {
  margin: 4px;
}
/* quick list member grid */
div.ql-group {
  margin-bottom: 15px;
}
div.ql-group span.item {
  line-height: 35px;
}
.table th {
  color: #a09f9f;
}
.text-lg {
  font-size: 1.4em;
  color: darkred;
}
div.help table {
  border-collapse: true;
  border: 1px solid darkgray;
}
div.help th,
div.help td {
  border: 1px solid darkgray;
  padding: 3px;
}
div.memberDetails {
  margin-bottom: 40px;
}
div.memberDetails .displayGroup {
  margin-bottom: 30px;
}
div.memberDetails .displayGroup .header {
  border-bottom: 2px solid darkgrey;
  margin-bottom: 20px;
}
div.memberDetails .displayGroup .header strong {
  font-size: 1.3em;
}
div.memberDetails .displayGroup .detail-menu button.add-detail-btn {
  margin: 10px;
}
div.memberDetails .displayGroup .adminGroup {
  padding: 0 20px;
  border-radius: 4px;
}
div.memberDetails .displayGroup .adminGroup .header {
  border-bottom: 2px solid lightgray;
}
div.memberDetails .displayGroup .adminGroup .header strong {
  font-size: 1.1em;
}
div.memberDetails .displayGroup .adminGroup .tags-and-fields {
  padding: 10px;
  background-color: #f8f8f8;
}
div.memberDetails .displayGroup .adminGroup .tags-and-fields .field {
  padding: 10px;
}
div.memberDetails .displayGroup .adminGroup .tags-and-fields .field:nth-of-type(2) {
  background: #ebebeb;
}
/* members list, showing matched numbers and reload all button */
div#reload-list-control {
  display: inline-block;
  margin-left: 20px;
  color: #ff0000;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 7px;
}
.tag-dates {
  padding-top: 9px;
}
