div#main-layout {
	padding-top: 51px; /* navbar is stuck to top */
}

div#main-col {
	min-height: 1850px; 
	background-color: #FFFFFF;
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

div#main-container {
	background-color: #FEFEFE;
}

a {
	-khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}
