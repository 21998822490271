div.fld-display {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px 0;
  margin-bottom: 4px; 
}

div.memberTag {
  font-weight: bold; 
  padding: 6px;
  border-radius: 8px;
}

div.memberTagDrawer {
  margin: 0 20px;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
}


.btn-default.btn-attr {
  /*font-weight: bold;*/
  color: #686869;
  background-color: #ccc;
  border-color: #AEA79F;
}

.btn-default.btn-attr:hover {
    color: #fff;
    background-color: #978e83;
    border-color: #92897e;
}